.QuickLinks_wrapper {
  margin-top:0.2rem;
  h3 {
    margin-bottom:10px;
  }
  .QuickLink {
    padding: 15px;
    margin-bottom: 10px;
    background-color: #f7fafd;
    border-left: 4px solid $primary-color;
    box-shadow: 0 1px 5px 0 rgba(0,0,0,0.1);
    cursor: pointer;
    transition: background-color .25s ease-out,color .25s ease-out;
    &:hover {
      opacity:0.85;
      border-left: 4px solid #f58917;
    }
    a {
      display:block;
      font-size:17px;
      &:after {
        content: "\f054";
        font-family: 'fontAwesome';
        color: #005baa;
        float:right;
        font-size:20px;
      }
    }
  }
}




