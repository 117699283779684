header {
  background: linear-gradient(0deg, #fff 50%, #f7fafd 0);

  // mobile
  #mobileNav {
    display: none;
  }
  #hamburger {
    display: none;
    width: 45px;
    height: 45px;
    z-index: 10;
    padding: 0.2rem;
    text-align: center;
    background: #005baa;
    cursor: pointer;
    float: right;
    margin: 40px;
    &:before {
      font-family: "FontAwesome";
      content: "\f0c9";
      color: #fff;
      font-size: 1.8rem;
    }
  }
  ul {
    list-style-type: none;
    margin-bottom: 0;
    margin-left: 0;
    li {
      display: inline-block;
    }
  }
  .header-wrapper {
    figure {
      margin: 0;
      padding-top: 15px;
      img {
        //max-width: 110px;
        max-width: 95px;
      }
    }
    div#top-menu {
      padding: 20px;
      float: right;
      cursor: pointer;
      ul.topmenu a {
        color: #333;
        padding: 0 5px;
        font-size: 15px;
      }
      #login {
        text-align: right;
        position: relative;
        span {
          color: #005baa;
          font-weight: bold;
        }
        span.padLock {
          color: #005baa;
          font-weight: bold;
          img {
            width: 15px;
            height: 15px;
            margin-top: -5px;
            margin-right: 5px;
          }
        }
        .inner {
          display: block;
          position: absolute;
          //top: 95%;
          //top: 85%;
          margin-top: 10px;
          right: 0;
          background-color: #005baa;
          width: 375px;
          padding: 30px;
          text-align: left;
          z-index: 999;
          color: #fff;
          input[type="submit"]{
            font-size: 1.125rem;
            text-align: center;
            line-height: 1;
            cursor: pointer;
            -webkit-appearance: none;
            transition: background-color .25s ease-out,color .25s ease-out;
            vertical-align: middle;
            border-radius: 0;
            font-size: .9rem;
            margin-bottom: 0.5rem;
            float: left;
            padding: .75rem 1.25rem;
            margin-top: .3125rem;
            margin-bottom: .3125rem;
            color: #fff;
            background-color: #f58917;
            border-bottom: 2px solid #f79d3d;
            border-right: 2px solid #f79d3d;
            border-left: 0;
            border-top: 0;
            float: left;
          }
          a, strong.intro {
            color: #fff;
          }
          div#loginContainer {
            strong.intro {
              padding-bottom: 10px;
              display: block;
              color: #fff;
            }
            div#login_rememberme_container {
              width: 20px;
              float: left;
            }
            div#btn_login {
              input[type="submit"] {
                width: 100%;
                background-color: #f58917;
                border: none;
                padding: 10px 0;
                &:hover {
                  opacity: 0.95;
                }
              }
            }
            #login_retrievepassword_container a {
              color: #fff;
              padding: 10px 0;
              display: block;
            }
          }
        }
      }
      #logOut {
        text-align: right;
        position: relative;
        width: 275px;
        .titleName {
          color: #005baa;
          font-weight: bold;
          span.padUnlock img {
            width: 15px;
            height: 15px;
            margin-top: -5px;
            margin-right: 5px;
          }
        }
        .inner {
          display: none;
        }
        &.open {
          .inner {
            display: block;
            position: absolute;
            top: 100%;
            background-color: #005baa;
            width: 100%;
            padding: 20px;
            margin-top: 10px;
            float: right;
            z-index: 999;
            color: #fff;
            nav ul {
              text-align: left;
              li {
                padding: 3px 0px;
                //a {
                //  &:hover {
                //    color: #002444;
                //  }
                //}
              }
            }
            a, .strong.intro {
              color: #fff;
            }
            div[id*="Block_Header_Uitloggen_"] {
              background-color: #f58917;
              position: absolute;
              width: 100%;
              right: 0;
              margin-top: 20px;
              padding: 15px 20px;
              text-align: center;
              &:hover {
                opacity: 0.95;
              }
            }
          }
        }
      }
    }
    div#main-menu {
      nav {
        ul {
          li {
            padding: 10px;
            text-transform: uppercase;
            span.fa.fa-chevron-right {
              display: none;
            }
            &:first-child {
              padding-left: 0;
            }
            ul.dropdown {
              display: none;
            }
            &.has-dropdown:after {
              content: "\f054";
              font-family: 'fontAwesome';
              padding-left: 8px;
              color: #005baa;
              -moz-transition: transform 0.2s;
              -webkit-transition: transform 0.2s;
              transition: transform 0.2s;
              &:hover {
                opacity: 0.8;
              }
            }
            &:hover {
              ul.dropdown {
                display: block;
                position: absolute;
                // Fix for non hover spot
                top: 99%;
                z-index: 2;
                background-color: #005baa;
                border-top: 1px solid #fff;
                margin-left: 0;
                li {
                  display: block;
                  padding: 0;
                  a {
                    padding: 15px;
                    color: #fff;
                    width: 100%;
                    float: left;
                  }
                }
              }
            }
          }
          span.searchIcon{
            float: right;
            padding: 10px 0;
            cursor:pointer;
            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
    section#search {
      background-color: #005baa;
      left: 0;
      position: absolute;
      top: 100%;
      width: 100%;
      display: none;
      padding: 20px 0;
      .row {
        max-width: 1240px;
        margin: 0 auto;
      }
      &.open {
        display: block;
      }
      input {
        &::placeholder {
          color: #fff;
        }
      }
      [data-block="search"] {
        input[type="text"] {
          width: 75%;
          float: left;
          background: transparent;
          border: 0;
          color: #fff;
          font-size: 1.125rem;
          font-style: italic;
          line-height: 3.25rem;
          margin: 0;
          box-shadow: inset 0 0px 0px;
          border-left: 2px solid #fff;
        }
        input[type="submit"] {
          height: 2.3125rem;
          width: 25%;
          background-color: #015095;
          color: #fff;
          border: 0;
        }
      }
    }
    //[data-block="login"] {
    //  background-color: $primary-color;
    //  color: #fff;
    //  display: none;
    //  padding: 4rem 0;
    //  position: absolute;
    //  z-index: 5;
    //  right: 0;
    //  left: 0;
    //  top: 100%;
    //  .inner {
    //    max-width: 1240px;
    //    margin: 0 auto;
    //    overflow:hidden;
    //    border-left: 4px solid #fff;
    //    h3, p {
    //      color:#fff;
    //    }
    //    .content {
    //      a {
    //        color:#fff;
    //      }
    //    }
    //  }
    //  &.open {
    //    display: block;
    //    span.btn.login{
    //      a {
    //        background-color: #004988;
    //        border: none;
    //        color: #fff;
    //        border-bottom: 2px solid #004988;
    //        border-right: 2px solid #004988;
    //      }
    //    }
    //  }
    //  #login_form_container {
    //    //width: 18.4375rem;
    //    background-color: #005baa;
    //  }
    //div#loginContainer {
    //  strong.intro {
    //    padding-bottom: 10px;
    //    display: block;
    //    color: #fff;
    //  }
    //  div#login_rememberme_container {
    //    width: 20px;
    //    float: left;
    //  }
    //  div#btn_login {
    //    input[type="submit"] {
    //      width: 100%;
    //      background-color: transparent;
    //      border: none;
    //      padding: 10px 0;
    //      border: 1px solid #fff;
    //      //&:hover {
    //      //  opacity:0.8;
    //      //}
    //    }
    //  }
    //  #login_retrievepassword_container a {
    //    color: #fff;
    //    padding: 10px 0;
    //    display: block;
    //    //&:hover {
    //    //  opacity:0.8;
    //    //}
    //  }
    //}
    //}
    div#mobile-menu {
      display: none;
      height: 100%;
      position: fixed;
      z-index: 1;
      top: 0;
      right: 0;
      background-color: #f7fafd;
      overflow-x: hidden;
      padding: 0;
      i.fa.fa {
        font-size: 2rem;
        top: 0;
        padding: 10px;
        text-align: center;
        height: 55px;
        width: 33.3%;
        cursor: pointer;
      }
      i.fa.fa-times {
        color: #005baa;
        background-color: #f7fafd;
      }
      i.fa.fa-user, i.fa.fa-search {
        color: #fff;
        background-color: #005baa;
      }
      ul {
        margin: 45px 0;
        li {
          width: 100%;
          cursor: pointer;
          a {
            color: #005baa;
            display: inline-block;
            font-size: 1rem;
            font-weight: 600;
            text-decoration: none;
            text-transform: uppercase;
            padding: 10px 55px 10px 55px;
            width: 100%;
          }
          ul.dropdown {
            display: none;
            list-style: none;
            margin: 0;
            padding: 0;
            background-color: #005baa;
            padding: .625rem 0;
            li {
              a {
                font-weight: 400;
                text-transform: capitalize;
                color: #fff;
              }
            }
          }
        }
        li.has-dropdown {
          &.open {
            -moz-transition: all .5s linear;
            -webkit-transition: all .5s linear;
            transition: all .5s linear;
            > a:after {
              -moz-transform: rotate(90deg);
              -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
            }
          }
          > a:after {
            content: "\f054";
            font-family: 'fontAwesome';
            padding-left: 8px;
            color: #005baa;
            float: right;
          }
        }
      }
    }

  }

}