body {
  &#zoekresultaten {
    .searchBar {
      width:75%;
      .control {
        margin:25px 0;
        input {
          margin:0;
          height:45px;
          &[type="text"]{
            background-color:#fff;
          }
          &[type="submit"]{
            width:100%;
          }
        }
        .fouten {
          color:#f58917;
        }
        .veld {
          display: inline-block;
          width: 85%;
        }
        .submitveld {
          width: 15%;
          float: right;
        }
      }
      h3 {
        font-size: 2.3125rem;
        color:#fff;
        text-transform:uppercase;
      }
    }
    // zoekresultaten
    .resultsFound {
      width: fit-content;
      .zoekresultaatHead {
        display:block;
        color:#fff;
        background-color: #005baa;
        padding: 15px;
        p {
          margin-bottom:0;
        }
        strong[class*="Results"]{
          font-size:18px;
        }
      }
    }
    .zoekresultaatHead {
      display:none;
    }
    .zoekresultatenOverzicht {
      overflow:hidden;
      li {
        padding: 1.875rem 2.5rem;
        border-left: 4px solid #005baa;
        background-color: #f7fafd;
        margin-bottom: 1.25rem;
        box-shadow: 0 1px 5px 0 rgba(0,0,0,0.1);
        cursor: pointer;
        overflow: hidden;
        &:hover {
          border-left: 4px solid #f58917;
        }
        .item {
          > h4 {
            width:calc(100% - 70px);
            display: inline-block;
          }
        }
      }
    }
  }
  &#contentpage {
    // tijdelijk
    .block.pages.variant2 {
      .text {
        max-width: 70%;
      }
    }
    div[id*="Content"] ul {
      margin-left:1.1rem;
    }
    .picture {
      position:relative;
      img {
        width:100%;
      }
      .innerWrapper {
        position: absolute;
        bottom: 5.25rem;
        left: 5.125rem;
        right: 5.125rem;
        // due to margin of headings
        padding: 10px;
        border-left: 5px solid #fff;
        .innerText {
          margin-left: 0.75rem;
          p, h3 {
            color:#fff;
          }
          p {
            margin-bottom:0;
            a {
              color:#fff;
              text-decoration:underline;
              &:hover {
                color:#f58917;
              }
            }
          }
        }
      }
    }
  }
}

