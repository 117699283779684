body#homepage {
  .meetingOverview {
    span {
      &.btn {
        display:none;
      }
    }
    #triangle-bottomright {
      display:none;
    }
  }
  #CCIPlaceHolder_Highlight {
    background-color: #f7fafd;
    .wrapper {
      background-image: url(/img/1/background-blue.svg);
      background-position:center bottom;
      background-repeat:no-repeat;
      background-size:cover;
    }
    .highlight {
      padding: 45px 0;
      max-width: 1000px;
      p {
        color:#fff;
      }
      figure {
        img {
          width:100%;
        }
        &:hover {
          opacity:0.85;
        }
      }
      section {
        position: relative;
        &:after {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: none;
          color: #FFF;
        }
      }
      .caption {
        position: absolute;
        bottom: 10%;
        left: 15%;
        border-left: 5px solid #fff;
        .inner {
          padding-left: 15px;
          max-width: 65%;
          float: left;
          h3, a {
            color: #fff;
          }
          h3 {
            font-size: 2.5rem;
            line-height: 1;
          }
        }
      }
    }
  }
  // intro
  #CCIPlaceHolder_Intro {
    .pagesSmall {
      .content {
        .main {
          display:none;
        }
      }
    }
    .inner{
      max-width: 1000px;
      padding: 25px 0;
      h2.text-right {
        float: right;
        font-weight:bold;
        text-align: right;
        line-height: 1;
      }
    }
  }
  // meeting overview
  .meetingOverview {
    article {
      .date {
        margin-right: 0;
        width: 50px;
      }
      .meeting-info {
        width: calc(100% - 50px);
      }
    }
  }
}