@import "_variables";
@media #{$small-only} {
  .bgimage {
    min-height: 500px!important;
    .inner {
      padding-right: 5%;
      .intro p {
        width:100%!important;
      }
    }
    .bounce {
      display:none;
    }
  }
  .highlight {
    section {
      margin-bottom:25px;
    }
  }
  header {
    div#mobileNav {
      height: 100%;
      width: 0;
      position: fixed;
      z-index: 1;
      top: 0;
      right: 0;
      background-color: #f7fafd;;
      overflow-x: hidden;
      padding-top: 60px;
      transition: 0.5s;
      .closebtn {
        position: absolute;
        top: 0;
        right: 25px;
        font-size: 36px;
        margin-left: 50px;
        width: auto;
        //a:before {
        //  content: "\f00d";
        //  font-family: "Fontawesome";
        //  color: #fff;
        //}
      }
      nav {
        ul {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }
}
