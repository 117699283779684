//Used for FAQ page
#subjects {
  h4.title {
    padding: 20px;
    margin-bottom: 0px;
    background-color: #005baa;
    color: #fff;
    cursor: pointer;
    .fa {
      float: right;
      -moz-transition: transform 0.2s;
      -webkit-transition: transform 0.2s;
      transition: transform 0.2s;
      &.rotate {
        transform: rotate(90deg);
      }
    }
  }
  ul {
    margin-left: 0 !important;
    list-style-type:none;
    li {
      border-bottom:1px solid #ededed;
      overflow:hidden;
      .content {
        padding: 20px;
        background-color: #f7fafd;
        p {
          margin:0;
        }
        .answer {
          margin:10px 0px;
        }
      }
    }
  }
}