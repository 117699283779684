// @import "https://use.fontawesome.com/ede1f9233a.css";
@import "_variables";
@import "fa/font-awesome";
@import "components/fonts";
@import "components/basic";
@import "components/header";
@import "components/homepage";
@import "components/meetingOverview";
@import "components/quickLinks";
@import "components/globalPages";
@import "components/meetingFilter";
@import "components/subjects";
@import "components/pagesSlider";
@import "mediaqueries/large";
@import "mediaqueries/medium";
@import "mediaqueries/small";

// SEH custom variables
$seh-blue: #005baa;
$seh-light-blue: #f7fafd;

@mixin button {
  text-align: center;
  line-height: 1;
  cursor: pointer;
  -webkit-appearance: none;
  transition: background-color .25s ease-out, color .25s ease-out;
  vertical-align: middle;
  border: 1px solid transparent;
  border-radius: 0;
  font-size: .9rem;
  margin-bottom: 0.5rem;
  float: left;
}

//.arrow {
//  display:none;
//  text-align: center;
//  position: absolute;
//  width: 100%;
//  bottom: 5%;
//  font-size:5rem;
//  &.bounce {
//    -moz-animation: bounce 2s infinite;
//    -webkit-animation: bounce 2s infinite;
//    animation: bounce 2s infinite;
//  }
//  .fa:before {
//    color: #fff;
//  }
//}
//
//@keyframes bounce {
//  0%, 20%, 50%, 80%, 100% {
//    transform: translateY(0);
//  }
//  40% {
//    transform: translateY(-30px);
//  }
//  60% {
//    transform: translateY(-15px);
//  }
//}

// slider styling
.slider {
  .caption {
    text-align: center;
    h3 {
      color: #fff;
      font-size: 3.75rem;
      font-weight: 700;
      line-height: 4.375rem;
      text-transform: uppercase;
      width: 80%;
      margin: 0 auto;
    }
  }
  .slick-dots {
    display: none;
  }
}

// global navigation widget
.navigation {
  ul {
    li {
      a:before {
        font-family: "fontawesome";
        content: "\f054";
        color: #f8aa57;
        padding-right: 5px;
      }
      a:hover:before {
        opacity: 0.7;
      }
    }
  }
}

// profile info
.SN_Overview {
  .add_mutation_link, .mutation_link {
    display: none !important;
  }
}

//login modal
//.block.login {
//  padding: 45px;
//  background-color: $seh-blue;
//  color: #fff;
//  position: relative;
//  h3 {
//    margin-bottom: 30px;
//    text-transform: uppercase;
//    font-weight: bold;
//  }
//  a, h3, p, span {
//    color: #fff;
//  }
//  .login_error, .login_error span {
//    color: #fff !important;
//  }
//  #lbl_username, #lbl_password {
//    display: none;
//  }
//  #login_retrievepassword_container {
//    a {
//      clear: both;
//      float: left;
//      line-height: 0;
//      margin-bottom: 20px;
//    }
//  }
//  #input_container_username {
//    &:before {
//      width: 45px;
//      float: left;
//      font-family: 'fontAwesome';
//      content: "\f007";
//      padding: 0 15px;
//      font-size: 1.5rem;
//      color: #005baa;
//      border-right: 1px solid #ededed;
//      background-color: #f7fafd;
//    }
//  }
//  #input_container_password {
//    &:before {
//      width: 45px;
//      float: left;
//      font-family: 'fontAwesome';
//      content: "\f023";
//      padding: 0 15px;
//      color: #005baa;
//      font-size: 1.5rem;
//      border-right: 1px solid #ededed;
//      background-color: #f7fafd;
//    }
//  }
//  input {
//    border: 0;
//    color: #444;
//    &[type="text"], &[type="password"] {
//      background-color: #e6e6e6;
//      width: calc(100% - 50px);
//      height: 36px;
//      padding-left: 10px;
//    }
//  }
//  // corner
//  &::before,
//  &::after {
//    content: '';
//    position: absolute;
//    bottom: 0;
//    right: 0;
//    transform: rotate(90deg);
//    border-color: transparent;
//    border-style: solid;
//  }
//
//  &::before {
//    border-width: 1.5em;
//    border-right-color: #f8aa57;
//    border-top-color: #f8aa57;
//  }
//
//  &::after {
//    border-radius: 0.4em;
//    border-width: 1.35em;
//    border-right-color: #f8aa57;
//    border-top-color: #f8aa57;
//  }
//}

// bounced icon

// Banner
.bgimage {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 0 3.125rem 0;
  min-height: 425px;
  background-position: top center;
  .mask {
    display: none;
  }
  .inner {
    margin: 0 auto;
    text-align: left;
    padding-top: 10%;
    position: absolute;
    // overrule mask
    z-index: 2;
    .intro {
      p {
        color: #fff;
        width: 55%;
      }
    }
    h1,h2,h3,h4,h5,h6 {
      color:#fff;
    }
    h1 {
      @include fontOpenSans700();
      color: #fff;
      line-height: 1;
      text-transform:uppercase;
    }
    span.btn {
      display: inline-block;
      padding-right: 10px;
      &.secondary a {
        background-color: #005baa;
        border-bottom: 2px solid #005baa;
        border-right: 2px solid #005baa;
      }
    }
  }
  // used for news detail
  .inner.full {
    .intro {
      p {
        color: #fff;
        width: 100%;
      }
    }
    h1 {
      font-weight: bold;
      color: #fff;
      line-height: 1;
    }
    span.btn {
      display: inline-block;
      padding-right: 10px;
      &.secondary a {
        background-color: #005baa;
        border-bottom: 2px solid #005baa;
        border-right: 2px solid #005baa;
      }
    }
  }
}

// News detail
body#news {
  div#Crumble {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
    float: left;
    ul {
      float: left;
      margin-left: 0;
      list-style-type: none;
      margin-bottom: 0;
      padding: 15px 0;
      li {
        float: left;
        margin-right: 10px;
        &:not(:last-child):after {
          content: "\f054";
          font-family: 'fontAwesome';
          padding-left: 8px;
          color: #005baa;
        }
      }
    }
  }
  .bgimage {
    min-height: 400px;
  }
  .fa-info-circle {
    color:#005baa;
    font-size: 1rem;
    cursor:pointer;
    div#disclaimer {
      display:none;
    }
    span {
      font-family: "Source Sans Pro",sans-serif;
      color:#005baa;
    }
    p {
      color:#111;
    }
    &:hover {
      div#disclaimer {
        display:block;
        p {
          width: 450px;
          background-color: #005baa;
          color: #fff;
          margin: 10px 0;
          padding: 10px;
          font-family: "Source Sans Pro",sans-serif;
        }
      }
    }
  }
  ul {
    margin-left:1.25rem;
  }
}

// Homepage aankondingen
div[id*="_Intro"] {
  .pagesSmall {
    article {
      margin: 10px 0!important;
      &:last-child {
        border-bottom: 0;
      }
    }
    .date {
      .day {
        font-size: 1.75rem;
      }
      .month {
        font-size: 1.15rem;
      }
      .year {
        font-size: 0.85rem;
      }
    }
    // hide intro text using same XSLT as normal news overview
    .text {
      p, .lees-meer {
        display: none;
      }
      h3, a {
        font-size: 1.125rem;
      }
    }
  }
}

.date {
  float: left;
  margin: 0 1.25rem .625rem 0;
  color: #005baa;
  text-align: center;
  .day {
    display: block;
    line-height: 1em;
    font-size: 2.1rem;
    font-weight: 600;
  }
  .month {
    display: block;
    line-height: 1em;
    font-size: 1.3125rem;
    text-transform: uppercase;
  }
  .year {
    display: block;
    line-height: 1em;
    font-size: 1.1rem;
  }
}

// news overview (pages)
.news {
  h2 {
    display: inline-block;
  }
  article {
    padding: 25px 0;
    &:not(:last-child) {
      border-bottom: 1px solid #ededed;
    }
    .text > p {
      margin-bottom: 0;
    }
    .lees-meer {
      margin: 10px 0;
      display: block;
    }
    h3 a {
      color: #000;
      font-weight: 600;
    }
  }
}

// footer
footer {
  .top {
    //padding: 45px 0;
    h3 {
      float: right;
      font-weight: bold;
      text-align: right;
      padding-left: 20px;
    }
  }
  ul.question {
    list-style-type: none;
    margin-bottom: 0;
    li {
      margin-bottom: 1.25rem;
      padding-left: 1.25rem;
      position: relative;
      padding: 0 25px;
      &:before {
        content: "\f0da";
        font-family: 'fontAwesome';
        font-size: 1rem;
        color: #f8aa57;
        left: 0;
        position: absolute;
      }
    }
  }
}

// docs
.docs {
  padding:10px;
  .docIcon {
    width: 60px;
    display: inline-block;
    float: left;
    .label {
      position:absolute;
      top:50%;
      z-index:2;
      background-color: #f58917!important;
      text-transform: uppercase;
      border-radius:2px;
      color:#FFF;
      font-weight:bold;
      font-size:11px;
      max-width: 50px;
      overflow: hidden;
    }
    span.fileIcon {
      width: 40px;
      height: 45px;
      float: left;
      position: relative;
      background: url(/img/1/fileIcon.png) 5px top/35px 45px no-repeat #fff;
    }
  }
  .docInfo {
    width: 100%;
    margin-left:60px;
    p {
      margin-bottom:0;
    }
  }
}


// Page editor image insert
p img {
  display:block;
}

.image-centered {
  display:block;
  float:none !important;
  margin:10px auto !important;
}

.image-left {
  float:left !important;
  margin:10px 20px 10px 0 !important;
}

.image-right {
  float:right !important;
  margin:10px 0 10px 20px !important;
}

// workgroup meetings
a[name="workgroup_unsubscribe"]{
  display:none;
}

/*i.fa.fa-times::before {
  content: "\f00d";
  font-family: 'fontAwesome';
}

i.fa.fa-check::before {
  content: "\f00c";
  font-family: 'fontAwesome';
}*/
