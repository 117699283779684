@import "_variables";

@media #{$large-up} {
  body#homepage {
    .bgimage {
      min-height:400px;
    }
    .bgimage .inner {
      width: 70%;
      padding-top: 10%;
    }
  }
}