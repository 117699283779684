@import "_variables";

@media #{$medium-down} {
  #main {
    transition: margin-left .5s;
    padding: 20px;
  }
  header {
    #mobileNav, #hamburger {
      display: block;
    }
    div#mobileNav {
      height: 100%;
      width: 0;
      position: fixed;
      z-index: 1;
      top: 0;
      right: 0;
      background-color: #f7fafd;;
      overflow-x: hidden;
      padding-top: 60px;
      transition: 0.5s;
      .closebtn {
        position: absolute;
        top: 0;
        right: 25px;
        font-size: 36px;
        margin-left: 50px;
        width: auto;
        //a:before {
        //  content: "\f00d";
        //  font-family: "Fontawesome";
        //  color: #fff;
        //}
      }
      nav {
        ul {
          padding-left: 30px;
          padding-right: 30px;
          li {
            width: 100%;
            a {
              padding: .625rem 1.25rem;
              text-decoration: none;
              color: #005baa;
              display: inline-block;
              transition: 0.3s;
              font-size: 1rem;
              font-weight: 600;
              text-transform: uppercase;
              padding-right: 0;
              width: 80%;
            }
            &.has-dropdown {
              //a:after {
              //  content:"\f054";
              //  font-family:"Fontawesome";
              //  float:right;
              //}
            }
            span.fa.fa-chevron-right {
              display:block;
              line-height: 2;
              cursor: pointer;
              width: 20%;
              font-size:25px;
              color:#005baa;
              text-align:center;
              -moz-transition: transform 0.2s;
              -webkit-transition: transform 0.2s;
              transition: transform 0.2s;
              float: right;
              &.rotate {
                transform: rotate(90deg);
              }
            }
          }
          &.dropdown {
            display: none;
            background-color: #005baa;
            color: #fff;
            margin-left: -30px;
            padding-left: 30px;
            width: calc(100% + 30px);
            padding: .625rem;
            li, a {
              color: #fff;
              font-weight: 400;
              text-transform: none;
              padding-left: 20px;
            }
          }
        }
      }
    }
  }
}