#filter{
  width:80%;
  #activeFilter{
    display:none;
    ul {
      margin-left: 1.1rem;
      li {
        list-style-type: disc;
        margin-bottom: 0;
      }
    }
  }
  h3.filter{
    margin-bottom: 1.25rem;
    font-weight: 600;
    color:#005baa;
  }
  ul {
    margin-left:0;
    li {
      margin-bottom: 10px;
      .title{
        width: 100%;
        display: inline-block;
        background-color: #dde9f4;
        color: #005baa;
        display: block;
        font-size: 1rem;
        padding: .725rem .95rem;
        cursor:pointer;
        &.open {
          i {
            transform: rotate(90deg);
          }
        }
      }
      i {
        float: right;
        font-size: 1rem;
        line-height: 1.6;
        cursor:pointer;
        -moz-transition: transform 0.2s;
        -webkit-transition: transform 0.2s;
        transition: transform 0.2s;
        &.fa-tag {
          float:left!important;
          padding-right:10px;
        }
      }
      .filterContent {
        padding: .95rem;
        background-color: #f7fafd;
        display:none;
        label {
          span {
            text-transform: capitalize;
          }
        }
        input[type="checkbox"]{
          margin-right:5px;
          margin-bottom:0;
        }
      }
    }
  }
}
// search results
.meetingOverview {
  h3.noResults {
    margin-bottom: 1.25rem;
    font-weight: 600;
    color:#005baa;
  }
}

// new
#container {
  .meetingOverview {
    .item {
      border-bottom:0;
      .inner {
        .heading {
          height:100px;
        }
        .organistator {
          width: 100%;
          float: left;
          margin: 10px 0;
        }
      }
    }
  }
}
#form-ui, #options {
  .option-set {
    margin-bottom:10px;
  }
  .filterTopic {
    strong {
      width: 100%;
      display: inline-block;
      background-color: #dde9f4;
      color: #005baa;
      display: block;
      font-size: 1rem;
      padding: .725rem .95rem;
      cursor:pointer;
      font-weight:normal;
      i {
        float: right;
        font-size: 1rem;
        line-height: 1.6;
        cursor:pointer;
        -moz-transition: transform 0.2s;
        -webkit-transition: transform 0.2s;
        transition: transform 0.2s;
        &.fa-tag {
          float:left!important;
          padding-right:10px;
        }
      }
    }
    &.open {
      .filterContent {
        display:block;
        padding: 10px;
        background-color: #f7fafd;
      }
      i {
        transform: rotate(90deg);
      }
    }
  }
  .filterContent {
    display:none;
    label {
      padding:2px 0;
    }
    input {
      margin: 0 5px 0 0;
    }
  }
}