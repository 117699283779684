// fonts
/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro regular';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/1/source_sans_pro/source-sans-pro-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
  url('/fonts/1/source_sans_pro/source-sans-pro-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/1/source_sans_pro/source-sans-pro-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/1/source_sans_pro/source-sans-pro-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('/fonts/1/source_sans_pro/source-sans-pro-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/1/source_sans_pro/source-sans-pro-latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-600 - latin */
@font-face {
  font-family: 'Source Sans Pro 600';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/1/source_sans_pro/source-sans-pro-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
  url('/fonts/1/source_sans_pro/source-sans-pro-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/1/source_sans_pro/source-sans-pro-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/1/source_sans_pro/source-sans-pro-latin-600.woff') format('woff'), /* Modern Browsers */
  url('/fonts/1/source_sans_pro/source-sans-pro-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/1/source_sans_pro/source-sans-pro-latin-600.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-700 - latin */
@font-face {
  font-family: 'Source Sans Pro 700';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/1/source_sans_pro/source-sans-pro-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
  url('/fonts/1/source_sans_pro/source-sans-pro-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/1/source_sans_pro/source-sans-pro-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/1/source_sans_pro/source-sans-pro-latin-700.woff') format('woff'), /* Modern Browsers */
  url('/fonts/1/source_sans_pro/source-sans-pro-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/1/source_sans_pro//source-sans-pro-latin-700.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-900 - latin */
@font-face {
  font-family: 'Source Sans Pro 900';
  font-style: normal;
  font-weight: 900;
  src: url('/fonts/1/source_sans_pro/source-sans-pro-v11-latin-900.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro Black'), local('SourceSansPro-Black'),
  url('/fonts/1/source_sans_pro/source-sans-pro-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/1/source_sans_pro/source-sans-pro-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/1/source_sans_pro/source-sans-pro-latin-900.woff') format('woff'), /* Modern Browsers */
  url('/fonts/1/source_sans_pro/source-sans-pro-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/1/source_sans_pro/source-sans-pro-latin-900.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

@mixin fontOpenSansRegular() {
  font-family:"Source Sans Pro regular", sans-serif;
}
@mixin fontOpenSans600() {
  font-family:"Source Sans Pro 600", sans-serif;
}
@mixin fontOpenSans700() {
  font-family:"Source Sans Pro 700", sans-serif;
}
@mixin fontOpenSans900() {
  font-family:"Source Sans Pro 900", sans-serif;
}

/*fontAwesome*/
@font-face {
  font-family: 'fontAwesome';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/1/font-awesome/fontawesome-webfont.eot'); /* IE9 Compat Modes */
  src: local('fontAwesome'), local('fontAwesome'),
  url('/fonts/1/font-awesome/fontawesome-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/1/font-awesome/fontawesome-webfont.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/1/font-awesome/fontawesome-webfont.woff') format('woff'), /* Modern Browsers */
  url('/fonts/1/font-awesome/fontawesome-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/1/font-awesome/fontawesome-webfont.eot.svg#fontAwesome') format('svg'); /* Legacy iOS */
}