@import "_variables";

// https://seh.livits.net/bijeenkomsten-overzicht
.meetingOverview {
  article {
    overflow: hidden;
    background-color: #f7fafd;
    padding: 20px;
    margin-bottom: 25px;
    // for absolute price tag
    position: relative;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,0.1);
    .date {
      margin-right: 0;
      width: 80px;
    }
    .meeting-info {
      width: calc(100% - 80px);
      h3 {
        font-weight:700;
      }
      span {
        &.btn {
          margin:10px 0;
        }
      }
      i {
        padding-right:5px;
      }
      .days {
        padding: 15px 0;
        float:left;
        width: 100%;
        strong {
          color:#005baa;
        }
      }
    }
  }
  div#triangle-bottomright {
    position: absolute;
    right: 0;
    bottom: 0;
    .inner {
      width: 0;
      height: 0;
      border-bottom: 75px solid #f58917;
      border-left: 100px solid transparent;
      span {
        position: absolute;
        top: 40px;
        width: 100px;
        left: 20px;
        text-align: center;
        font-size: 16px;
        transform: rotate(-35deg);
        display: block;
        font-size: 85%;
        color: #fff;
      }
    }
  }
}

// meeting overview new
.meetingOverview {
  .item {
    .inner {
      background-color: #f7fafd;
      padding: 20px;
      margin-bottom: 25px;
      position: relative;
      box-shadow: 0 3px 3px 0 rgba(0,0,0,0.1);
      padding: 20px;
      position: relative;
      .heading {
        height: auto;
      }
      .days strong {
        color:#005baa;
      }
    }
  }
}