// global
input:not([type]), input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], input[type="color"], textarea {
  background-color: #f2f2f2;
}
figure {
  margin: 0;
}
ul {
  margin-left: 0;
}

h1 {
  font-weight: 700;
  text-transform: uppercase;
}

main {
  .columns a {
    &:hover {
      color:#f58917;
    }
  }
  .intro a, .content a{
    &:hover {
      color:#f58917;
    }
  }
  span.btn a{
    &:hover {
      color:#fff;
    }
  }
}

h2 {
  font-weight: 600;
}

h3, h4, h5, h6 {
  font-weight: 400;
}

section {
  .intro p {
    font-weight:700;
  }
  > h2:first-of-type {
    margin-top:0;
    margin-bottom:0.75rem;
  }
  // for blocks
  > div > section {
    padding:0;
  }
}

body#contentpage {
  section {
    padding:0.75rem 0;
  }
  table {
    tr {
      &:first-of-type {
        background-color:#005baa;
        td {
          color:#fff;
        }
      }
      &:nth-of-type(even){
        background-color:#f7fafd;
      }
    }
  }
}


.accordion .accordion-navigation>a, .accordion dd>a {
  background: #005baa;
  color: #fff;
  &:hover {
    opacity:0.95;
    background-color:#005baa;
  }
}

.accordion .accordion-navigation.active>a, .accordion dd.active>a {
  background: #005baa;
  color: #fff;
}

.accordion .accordion-navigation>.content.active, .accordion dd>.content.active {
  background: #f7fafd;
}

.pages.variant7 .accordion .accordion-navigation>a:after {
  border-color: #fff transparent transparent transparent;
}

.fouten {
  color:#fff;
}

.lightblue {
  background-color:#f7fafd;
}

div#cookieMsg {
  background:rgba(0, 91, 170, 0.95);
}

// mixins
@mixin buttonBlue {
  background-color: #000;
  border: 2px solid #fff;
  color: #fff;
  :after {
    content: " »";
  }
}